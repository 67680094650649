import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./tailwind.css"
import BlogList from "../components/blog_list"
import FeaturedPost from "../components/featured_post"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <FeaturedPost post={data.featuredPosts.edges[0]} />
    <BlogList color="bg-white" title="Recent Articles" description="The most recent articles on Production Ready GraphQL" posts={data.allMarkdownRemark.edges} />
  </Layout>
)

export const query = graphql`
  query BlogPostList {
    featuredPosts: allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}}}) {
      edges {
        node {
          postImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          frontmatter {
            title
            path
            date
            tag
            author
            description
            imageUrl
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 3, filter: { frontmatter: { featured: { eq: null } } }) {
      edges {
        node {
          postImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          frontmatter {
            title
            path
            date
            tag
            author
            description
            imageUrl
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

export default IndexPage
