import React from "react"

import me from "../images/me.jpg"

import { Link } from "gatsby"

import Img from "gatsby-image"

const FeaturedPost = ({ post }) => (
  <div className="flex justify-between mt-6 mb-12">
    <div className="flex flex-col rounded-lg shadow-lg w-full lg:w-7/12">
      <div className="flex-shrink-0">
        <Img className="h-64 w-full object-cover" fluid={post.node.postImage.childImageSharp.fluid} alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-300 text-black mb-2">
            { post.node.frontmatter.tag }
          </span>
          <Link to={post.node.frontmatter.path} className="block">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
            { post.node.frontmatter.title }
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500">
            { post.node.frontmatter.description }
            </p>
          </Link>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <Link to={post.node.frontmatter.path}>
              <img className="h-10 w-10 rounded-full" src={me} alt="" />
            </Link>
          </div>
          <div className="ml-3">
            <p className="text-sm leading-5 font-medium text-gray-900">
              <a href="#" className="hover:underline">
              { post.node.frontmatter.author }
              </a>
            </p>
            <div className="flex text-sm leading-5 text-gray-500">
              <time dateTime="2020-03-16">
              { post.node.frontmatter.date }
              </time>
              <span className="mx-1">
                &middot;
              </span>
              <span>
                6 min read
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col rounded-lg shadow-lg hidden lg:block lg:w-2/5">
      <div className="flex-shrink-0">
        <img className="h-64 w-full object-cover" src="https://book.productionreadygraphql.com/static/card.png" alt="" />
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <a href="#" className="hover:underline">
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-pink-300 text-black mb-2">
              New!
            </span>
          </a>
          <a href="https://book.productionreadygraphql.com">
            <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
            The Production Ready GraphQL Book is Now Available!
            </h3>
            <p className="mt-3 text-base leading-6 text-gray-500">
            Learn how to design and build predictable, performant, and secure GraphQL APIs at scale. A 180-pages book full of tips I've aquired over the past 5 years. 
            &nbsp;<span className="inline text-left underline text-pink-500" href="https://book.productionreadygraphql.com">Check it out &rarr;</span>
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default FeaturedPost